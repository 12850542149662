import React, { Suspense, lazy } from "react";

import { Switch, Route } from "wouter";
import ScrollToTop from "./components/ScrollToTop";
import CookieWidget from "./components/CookieWidget";

const Home = lazy(() => import("./pages/Home"));
const Document = lazy(() => import("./pages/Document"));
const Cookie = lazy(() => import("./pages/Cookie"));
const Company = lazy(() => import("./pages/Company"));
const Vacancy = lazy(() => import("./pages/Vacancy"));

window.onload = () => {
  window.scrollTo(0, 0);
};

function App() {
  return (
    <div className="font-PPPangramSans">
      <ScrollToTop />
      <Switch>
        <Route path="/docs/:uid">
          <Suspense path="/docs/:uid">
            <Document />
            <CookieWidget />
          </Suspense>
        </Route>
        {/* /policy и /terms роуты держим для обратной совместимости, 
            т.к. их скидывали для подключение эквайрингов */}
        <Route path="/policy">
          <Suspense path="/policy">
            <Document uid="privacy-policy" />
            <CookieWidget />
          </Suspense>
        </Route>
        <Route path="/terms">
          <Suspense path="/terms">
            <Document uid="terms-and-conditions" />
            <CookieWidget />
          </Suspense>
        </Route>

        <Route path="/vacancy/:uid">
          <Suspense>
            <Vacancy />
            <CookieWidget />
          </Suspense>
        </Route>

        <Route path="/cookie">
          <Suspense path="/cookie">
            <Cookie />
          </Suspense>
        </Route>
        <Route path="/company">
          <Suspense path="/company">
            <Company />
            <CookieWidget />
          </Suspense>
        </Route>
        <Route path="/">
          <Suspense path="/">
            <Home />
            <CookieWidget />
          </Suspense>
        </Route>
      </Switch>
    </div>
  );
}

export default App;
