import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Fetch from "i18next-fetch-backend";

i18n
  .use(Fetch)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en",
    ns: ["translation", "policy", "cookie"],
    load: "languageOnly",
    supportedLngs: ["en", "ko"],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
