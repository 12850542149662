import { useLocalStorage } from 'react-use'
import { useTranslation } from 'react-i18next'

function CookieWidget() {
  const [consent, setConsent] = useLocalStorage('consent_to_cookies', false)
  const [t] = useTranslation('cookie')

  const handleClick = () => {
    setConsent(true)
  }

  if (consent) return null

  return (
    <div className="fixed bottom-[5px] md:bottom-[30px] inset-x-[5px] md:inset-x-[30px] bg-white rounded-[15px] md:rounded-[30px] p-[15px] md:p-[30px] z-[999] text-cookie shadow-nav mx-auto text-blue-300">
      <div className="flex justify-between items-center">
        <div className="cookie-editor max-w-[400px] md:max-w-[600px]">
          <div dangerouslySetInnerHTML={{ __html: t('widget') }} />
        </div>
        <button
          onClick={handleClick}
          className="h-[40px] md:h-[60px] inline-flex px-[20px] md:px-[40px] items-center bg-blue-300 rounded-full text-white whitespace-nowrap"
        >
          {t('button.accept')}
        </button>
      </div>
    </div>
  )
}

export default CookieWidget
